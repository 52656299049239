<template>
  <div class="timeline-page">
    <v-progress-linear v-if="loading"></v-progress-linear>
    <!-- comments texteditor -->
    <v-card :class="['ma-3', $vuetify.theme.dark ? '' : 'grey lighten-5 my-0']"
    v-show="showEditor" tile flat :loading="editorLoading" :disabled="editorLoading">
      <v-card-title class="pa-0">
        <v-layout row wrap align-center class="ma-0 pt-2 pl-1">
          <template v-for="(btn, index) in ctrlButtons">
            <v-menu v-model="editorAddLinkMenu" absolute bottom :key="index" :close-on-click="true" :open-on-click="false"
            :close-on-content-click="false" v-if="btn.isLinkHandler">
              <template #activator="{ on, attrs }">
                <v-btn outlined x-small :class="['px-1', btn.class]" height="25" :key="index" @click="btn.click && btn.click(btn)"
                :title="btn.title" v-on="on" v-bind="attrs" :ref="btn.ref">
                  <v-icon size="20"> {{ btn.icon }} </v-icon>
                </v-btn>
              </template>
              <v-card width="400" class="pa-0" tile>
                <v-card-title class="px-3 py-1">
                  <v-layout align-center class="ma-0">
                    <v-flex lg11 class="text-truncate white--text">
                      <label class="body-1">
                        {{ $t('selectedSelection') }}:
                        <span class="body-2 font-weight-bold"> {{ selectionString }} </span>
                      </label>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex lg1 class="text-right">
                      <v-btn icon small dark class="mr-n2" @click="editorAddLinkMenu = false">
                        <v-icon size="20"> mdi-close-circle </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-title>
                <v-card-text class="pa-2">
                  <v-form ref="editorLinkContentForm">
                    <v-text-field outlined dense hide-details placeholder="link" class="d-flex align-center" v-model="editorLinkContent"
                    autofocus :rules="editorLinkContent ? domainValidation : $_requiredValidation" validate-on-blur>
                      <template #append-outer>
                        <div class="d-flex mt-n1">
                          <v-btn fab x-small color="primary" height="24" width="24" @click="executeJSCommand({ command: 'createLink', value: editorLinkContent })">
                            <v-icon x-small> mdi-check </v-icon>
                          </v-btn>
                          <v-btn fab x-small color="error" class="ml-1" height="24" width="24" @click="executeJSCommand({ command: 'unlink' })">
                            <v-icon x-small> mdi-link-variant-off </v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-text-field>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-btn outlined x-small :class="['px-1', btn.class]" height="25" :key="index" @click="btn.click && btn.click()"
            :title="btn.title" v-else>
              <v-icon size="20"> {{ btn.icon }} </v-icon>
            </v-btn>
          </template>
        </v-layout>
      </v-card-title>
      <v-card-text class="d-flex align-center pa-1" data-editor-root-container>
        <!-- editor content -->
        <div :contenteditable="!editorLoading" aria-placeholder="true" :class="['editor-content pa-1', $vuetify.theme.dark ? 'themedark' : '']"
          @keypress="onKeyPressComment" @paste="onEditorPasteEvent" style="width: 700px;">
        </div>
        <!-- mentions users list -->
        <v-menu v-model="editorMentionsMenu" :position-x="editorMentionsMenuPos.left" :position-y="editorMentionsMenuPos.top"
        nudge-right="22" :close-on-content-click="false">
          <v-card>
            <v-card-title class="pa-0">
              <v-text-field solo flat dense hide-details v-model="mentionUserSearch" ref="menuListSearch"
              persistent-placeholder :placeholder="$t('search')" autofocus></v-text-field>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-list dense max-height="200" class="body-2 py-1 user-mentions-list overflow-auto"
              v-model="selectedUser" @change="executeJSCommand({ command: 'insertUser', value: user })">
                <v-list-item v-for="(user, index) in mentionUserList" :key="index"
                :tabindex="(index + 1)" @click.stop="executeJSCommand({ command: 'insertUser', value: user })">
                  {{ user.name }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      editorAddLinkMenu: false,
      editorMentionsMenu: false,
      editorMentionsMenuPos: {},
      editorLoading: false,
      commentFiles: [],
      editorLinkContent: 'https://',
      showEditor: true,
      editActivityObj: null,
      mentionUserSearch: '',
      mentionUserList: [],
      selectedUser: null,
      caretActiveElement: null,
      selection: null,
      selectionString: '',
      //
      loading: false
    }
  },
  mounted () {
    window.addEventListener('keydown', (ev) => {
      if ((ev && ev.key === 'Backspace')) { // handle backspace event at texteditor
        if ((this.$refs.menuListSearch && !this.$refs.menuListSearch.hasFocused)) {
          this.editorMentionsMenu = false
        }
      }
    })
  },
  computed: {
    ctrlButtons () {
      return [{
        icon: 'mdi-undo',
        title: this.$t('undo'),
        class: '',
        click: () => this.executeJSCommand({ command: 'undo' })
      }, {
        icon: 'mdi-redo',
        title: this.$t('redo'),
        class: 'mx-1',
        click: () => this.executeJSCommand({ command: 'redo' })
      }, {
        icon: 'mdi-alpha-b',
        title: this.$t('bold'),
        class: '',
        click: () => this.executeJSCommand({ command: 'bold' })
      }, {
        icon: 'mdi-alpha-i',
        title: this.$t('italic'),
        class: 'mx-1',
        click: () => this.executeJSCommand({ command: 'italic' })
      }, {
        icon: 'mdi-alpha-u',
        title: this.$t('underline'),
        class: '',
        click: () => this.executeJSCommand({ command: 'underline' })
      }, {
        icon: 'mdi-format-strikethrough',
        title: this.$t('strikethrough'),
        class: 'mx-1',
        click: () => this.executeJSCommand({ command: 'strikethrough' })
      }, {
        icon: 'mdi-format-list-numbered',
        title: this.$t('numberedList'),
        class: '',
        click: () => this.executeJSCommand({ command: 'insertOrderedList' })
      }, {
        icon: 'mdi-format-list-bulleted',
        title: this.$t('unOrderedList'),
        class: 'mx-1',
        click: () => this.executeJSCommand({ command: 'insertUnorderedList' })
      }, {
        icon: 'mdi-link-variant',
        title: this.$t('pageLink'),
        class: '',
        isLinkHandler: true,
        click: () => {
          const anchorNode = window.getSelection().anchorNode.parentElement
          const focusNode = window.getSelection().focusNode.parentElement
          let element
          if (anchorNode.hasAttribute('href')) element = anchorNode
          if (!element && focusNode.hasAttribute('href')) element = focusNode
          if (element) {
            window.getSelection().selectAllChildren(element)
            this.selection = this.saveSelection()
            this.editorLinkContent = element.getAttribute('href')
            this.selectionString = element.textContent
            this.editorAddLinkMenu = true
            return
          }

          const selectedSelectionString = window.getSelection().toString().trim()
          if (selectedSelectionString.length) {
            this.selection = this.saveSelection()
            this.selectionString = selectedSelectionString
            this.editorLinkContent = 'https://'
            this.editorAddLinkMenu = true
          } else {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'plsMakeSelection' })
          }
        }
      }, {
        icon: 'mdi-restore',
        title: this.$t('restore'),
        class: 'mx-1',
        click: () => {
          const editorElem = document.querySelector('.editor-content')
          editorElem.innerHTML = ''
          // eslint-disable-next-line
          this.commentFiles = []
          // eslint-disable-next-line
          this.editActivityObj = null
          editorElem.focus()
        }
      }]
    },
    domainValidation () {
      return [
        (v) => v ? /(((https?:\/\/)|(www\.))[^\s]+)$/g.test(v) || 'Invalid Domain Address' : true
      ]
    }
  },
  // watch: {
  //   mentionUserSearch: {
  //     handler (term) {
  //       if (term) {
  //         this.mentionUserList = this.getUsers.filter((user) => (!!user.isactive && (user._id !== this.userDetails._id) && user.name.toLowerCase().includes(term.toLowerCase())))
  //       } else {
  //         this.mentionUserList = this.getUsers.filter((user) => !!user.isactive && (user._id !== this.userDetails._id))
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  methods: {
    onKeyPressComment (event) {
      setTimeout(() => { // create first { div } element
        const editorElem = document.querySelector('.editor-content')
        if (!editorElem.children.length) {
          let caretActiveElement = window.getSelection().anchorNode.parentElement
          if (caretActiveElement.hasAttribute('data-editor-root-container')) {
            caretActiveElement = document.querySelector('.editor-content')
          }
          if (caretActiveElement.innerHTML.length === 1) {
            caretActiveElement.innerHTML = `<div data-row-container="1">${caretActiveElement.innerHTML}</div>`
            this.setCaretPosition(caretActiveElement, 1)
          }
        }
      }, 0)

      if ((event.code === 'Enter') && (!event.ctrlKey)) {
        const editorElem = document.querySelector('.editor-content')
        setTimeout(() => {
          let divRowElementIndex = 1
          for (let divRowElement of editorElem.children) {
            divRowElement.setAttribute('data-row-container', divRowElementIndex)
            divRowElementIndex += 1
          }
        }, 0)
      }

      // handling only { ctrl + enter }
      if ((event.code === 'Enter' && event.ctrlKey) && (!event.shiftKey)) {
        this.addNewComment()
      }

      // handling { @ } and other keypress events
      if (event.key === '@') {
        event.preventDefault()
        this.executeJSCommand({ command: 'insertHTML', value: `<span id="atChar">@</span>` })
        let caretPos = this.getCaretCoordinates()
        caretPos = caretPos || { x: 80, y: 278 }
        this.editorMentionsMenuPos = { top: (caretPos.y + 7), left: (caretPos.x - 5) }
        this.mentionUserSearch = ''
        this.caretActiveElement = window.getSelection().anchorNode.parentElement
        this.editorMentionsMenu = true
      } else {
        this.editorMentionsMenu = false
      }
    },
    onEditorPasteEvent (event) { // handles onpaste event in texteditor
      event.preventDefault()
      let pastedText = (event.originalEvent || event).clipboardData.getData('Text') // gets pasted text as it is with styles
      let isImagePasted = false

      if (pastedText && pastedText.length) {
        pastedText = (event.originalEvent || event).clipboardData.getData('text/plain') // gets pasted text as plain without any styles
        pastedText = this.detectURLs(pastedText)
      } else {
        let item = (event.originalEvent || event).clipboardData.items[0]
        if (item.type.indexOf('image') === 0 || (item.type && item.type.includes('image'))) {
          let blob = item.getAsFile()
          let reader = new FileReader()
          reader.onload = (event) => {
            isImagePasted = true
            pastedText = event.target.result
          }
          reader.readAsDataURL(blob)
        }
      }

      setTimeout(() => {
        let caretActiveElement = window.getSelection().anchorNode.parentElement
        if (caretActiveElement.hasAttribute('data-editor-root-container')) {
          caretActiveElement = document.querySelector('.editor-content')
        }
        if (isImagePasted) {
          if (event.target.hasAttribute('data-row-container')) {
            event.target.innerHTML += `
              <img src="${pastedText}" alt="image">
            `
            this.setCaretPosition(caretActiveElement)
          } else {
            if (event.target.parentElement.hasAttribute('data-row-container')) { // to avoid inserting image into { <br> } like tags
              caretActiveElement = event.target.parentElement
              if (caretActiveElement.firstChild.nodeName === 'BR') caretActiveElement.firstChild.remove()
              caretActiveElement.innerHTML += `
                <img src="${pastedText}" alt="image">
              `
              this.setCaretPosition(caretActiveElement)
            } else { // to insert root tag when there isn't one
              caretActiveElement.innerHTML += `
              <div data-row-container="1">
                <img src="${pastedText}" alt="image">
              </div>
              `
              this.setCaretPosition(caretActiveElement)
            }
          }
        } else {
          const editorElem = document.querySelector('.editor-content')
          if (editorElem.children.length) {
            this.executeJSCommand({ command: 'insertHTML', value: pastedText })
          } else {
            caretActiveElement.innerHTML = `<div data-row-container="1">${pastedText}&nbsp;</div>`
            this.setCaretPosition(caretActiveElement, 1)
          }
        }
      }, 10)
    },
    executeJSCommand ({ command, value }) {
      const editorElem = document.querySelector('.editor-content')
      if (command === 'insertUser') {
        if (this.caretActiveElement.innerHTML) {
          this.caretActiveElement.innerHTML += `<span id="data-mention-container"><span data-user-id="${value._id}" data-user-email="${value.email}" class="font-weight-medium user-mention" disabled>${value.name}</span>&nbsp;</span>`
        }
        this.setCaretPosition(this.caretActiveElement)
        this.editorMentionsMenu = false
      } else if (command === 'createLink') {
        if (this.$refs.editorLinkContentForm[0] && this.$refs.editorLinkContentForm[0].validate()) {
          this.restoreSelection(this.selection)
          editorElem.ownerDocument.execCommand(command, false, value)
          this.editorLinkContent = null
          this.editorAddLinkMenu = false
          this.clearSelection()
          // this.setCaretPosition(editorElem, 1)
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
        }
      } else if (command === 'unlink') {
        this.restoreSelection(this.selection)
        editorElem.ownerDocument.execCommand(command, false, '')
        this.editorAddLinkMenu = false
        this.clearSelection()
      } else {
        editorElem.ownerDocument.execCommand(command, false, value)
      }
    },
    getCaretCoordinates () { // this method returns the coordinates of the caret
      let result = {}
      if (typeof window.getSelection !== 'undefined') {
        const selection = window.getSelection()
        if (selection.rangeCount) {
          const range = selection.getRangeAt(0).cloneRange()
          range.collapse(true)
          result = range.getClientRects()[0]
        }
      }
      return result
    },
    // to set caret position immediately after pasted or entered { text/ element }
    setCaretPosition (element, caretPos = 2) {
      let range = document.createRange()
      let sel = window.getSelection()
      range.setStart(element, caretPos)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
      element.focus()
    },
    detectURLs (message) { // replaces url in plain string
      if (!message) return ''
      let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
      return message.replace(urlRegex, function (url) {
        let hyperlink = url
        // eslint-disable-next-line
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink
        }
        return '<a href="' + hyperlink + '" target="_blank" class="pd-editor-link">' + url + '</a>'
      })
    },
    replaceSelectionWithHtml ({ selection, html }) {
      let range
      selection = selection || window.getSelection
      if (selection && selection() && selection().getRangeAt) {
        range = selection().getRangeAt(0)
        range.deleteContents()
        let div = document.createElement('div')
        div.innerHTML = html
        let frag = document.createDocumentFragment()
        // let child
        // eslint-disable-next-line
        // while (child = div.firstChild) {
        //   frag.appendChild(child)
        // }
        frag.appendChild(div.firstChild)
        range.insertNode(frag)
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange()
        range.pasteHTML(html)
      }
    },
    saveSelection () {
      if (window.getSelection) {
        var sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          return sel.getRangeAt(0)
        }
      } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange()
      }
      return null
    },
    restoreSelection (range) {
      if (range) {
        if (window.getSelection) {
          var sel = window.getSelection()
          sel.removeAllRanges()
          sel.addRange(range)
        } else if (document.selection && range.select) {
          range.select()
        }
      }
    },
    clearSelection () {
      if (window.getSelection) {
        if (window.getSelection().empty) { // Chrome
          window.getSelection().empty()
        } else if (window.getSelection().removeAllRanges) { // Firefox
          window.getSelection().removeAllRanges()
        }
      } else if (document.selection) { // IE?
        document.selection.empty()
      }
    },
    getBodyContent() {
      const editorElem = document.querySelector('.editor-content')
      // const messageLength = editorElem.innerText
      // adding { target } attribute for anchor elements
      // const anchorElements = document.querySelectorAll('.editor-content a')
      // for (let anchorElemIndex of anchorElements.length) {
      //   anchorElements[anchorElemIndex].target = '_blank'
      // }
      // eslint-disable-next-line
      let editorValue = editorElem.innerHTML.toString().replace(/\&nbsp;/g, '')
      // eslint-disable-next-line
      // editorValue = editorValue ? editorValue.replace(/\<br>/g, '') : editorValue
      return editorValue
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', () => {})
  }
}
</script>
<style>
.timeline-page .v-timeline-item__body {
  max-width: calc(90% - 48px) !important;
}

/* comments texteditor */
.timeline-page .editor-content {
  min-height: 70px;
  height: 100%;
  border: 1px solid black;
  padding: 2px;
  outline: none;
  color: black;
}
.timeline-page .editor-content span.user-mention {
  color: orangered;
  /* color: #a61f49; */
}
.timeline-page .editor-content.themedark {
  border: 1px solid white;
  color: white;
  caret-color: white;
}
.timeline-page .editor-content img {
  height: 14vh;
  width: 12vw;
  margin-top: 5px;
}
.timeline-page .editor-message-container {
  font-size: 0.93rem !important;
  /* display: flex !important; */
  max-width: 66vw !important;
}
.timeline-page .editor-message-container + span.caption {
  border: 1px solid grey;
  padding: 2px 4px 2px 0;
  text-transform: lowercase;
  font-weight: 500;
}
.timeline-page .editor-message-container img {
  width: 16vw;
  height: 18vh;
  margin: 4px 0 -2px 0;
}
.timeline-page .editor-message-container span.user-mention {
  color: orangered;
}
</style>
